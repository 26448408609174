import React from 'react';
import Layout from '../../../../components/Layout';
import { Container, Row, Column } from '../../../../components/Grid';
import Section from '../../../../components/Section';
import { Link } from 'gatsby';
import Video from '../../../../components/Video';
import Icon from '../../../../components/Icon';

const InnovationTakingShape = () => (
  <Layout
    title="Innovation Taking Shape"
    className="annual-challenge has-video"
  >
    {/* Intro Title and Video */}
    <Section>
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="/annual-challenge/about-the-challenge">
              Annual Challenge
            </Link>
          </li>
          <li className="breadcrumb">
            <Link to="/annual-challenge/event-gallery-videos">
              Inside the Challenge
            </Link>
          </li>
          <li className="breadcrumb">
            <Link to="/annual-challenge/event-gallery-videos">Videos</Link>
          </li>
          <li className="breadcrumb active">Innovation Taking Shape</li>
        </ul>
        <Row>
          <Column size={10} offset={1} className="no-hero">
            <h1 className="page-title">Innovation Taking Shape</h1>
            {/* Video Container */}
            <div className="video-container__with-pager">
              <Link to="../shaping-futures" className="previous">
                <Icon name="arrowleftgray" />
              </Link>
              <div className="videoWrapper">
                <Video
                  guidSrc="2b206132-add2-4b84-9926-a93d1bd2ce2d"
                  className="de-vid"
                ></Video>
              </div>
              <Link to="../journey-to-3m" className="next">
                <Icon name="arrowrightgray" />
              </Link>
            </div>
            <h3>
              Learn how the Young Scientist Challenge 2015 finalists were
              impacted by science and how they are inspired to apply science to
              life.
            </h3>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default InnovationTakingShape;
